<template>
    <div class="main-section-container">
      <div class="header">
        <h1 class="site-heading header"> ALL PROJECTS </h1>
      </div>

      <div v-if="showFilter" class="filter-cotainter">
        <h1 class="filter-title">Filter</h1>
        <div class="filter-options-container">
          <FilterOption @click="toggleFilter" v-for="tag in tagOptions" 
            :key="tag"
            :tagName="tag"
            :filterSelected="filterSelection"
            :filterFunction="setTag"
          />
        </div>
      </div>

      <div class="main-section-links">
          <TestMainLink v-for="project in filteredProjects"
            :projectData="project"
            :imageSource="require(`@/assets/images/projectLinks/${project.imageSource}`)"
            :key="project.index"
          />
      </div>
    </div>
</template>

<script>
import TestMainLink from "@/components/TestMainLink";
import projectDetails from '@/data/projectDetails.js';
import FilterOption from "@/components/page_elements/FilterOption";
export default {
  name: 'WebProjects',
  components: {
    TestMainLink,
    FilterOption,
  },
  data() {
    return {
      allProjects: projectDetails,
      filterSelection: '',
      showFilter: true,
      tagOptions: ['game', 'web project', 'touch', 'animation'],
    }
  },
  computed: {
    filteredProjects() {
      if ( this.filterSelection === '') return this.allProjects;
      return this?.allProjects.filter(project => 
        project.tags?.includes(this.filterSelection)
      );
    }
  },
  methods: {
    setTag(tag) {
      if (this.filterSelection === tag) {
        this.filterSelection = '';
        return;
      }
      if (this.filterSelection !== tag) {
        this.filterSelection = tag;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter; 
    }
  }
}
</script>

<style lang="scss" scoped>
  $tablet-width: 950px;

  .header {
    position: relative;
    width: 100%;
  }

  .filter-button-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 85px;
    border: 3px black solid;
    border-radius: 20px;
    padding: 5px 10px;
    right: 5px;
    bottom: 19px;
    cursor: pointer;

    @media only screen and (max-width: $tablet-width) {
      width: 50px;
      padding: 5px 0px;
      justify-content: center;
    }
  }

  .filter-cotainter {
    display: flex;
    height: 100%;
    width: 100%;
    background: white;
    padding: 5px 0px;
    border-radius: 0px 0px 25px 25px;
    margin: -20px 0px 20px 0px;
  }

  .filter-arrow {
    transition: transform 0.5s;
    width: 25px;

    &.open {
      transform: scaleY(-1)
    }

    @media only screen and (max-width: $tablet-width) {
      display: none;
    }
  }

  .filter-title {
    width: 25%;
    text-align: center;
  }

  .filter-options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
  }

</style>