export default [
    {
        text: "weather checker",
        imageSource: 'weatherLink3.png',
        routerLink: {name: 'WeatherChecker'},
        detailsText: "Check the weather in a city or your local area using an open API",
        tags: ['web project', 'touch'],
    },
    {   
        text: "Basketball ScoreSheet",
        imageSource: 'bballSheet.png',
        routerLink: {name: 'bballSheet'},
        detailsText: "A tool to score a Basketball game and produce a score sheet and box score",
        tags: ['web project', 'touch'],
    },
    {
        text: "3D Raycaster",
        imageSource: 'raycasterLink.png',
        routerLink: {name: 'raycaster'},
        detailsText: "Move around a raycasted 3D map",
        tags: ['game', 'animation'],
    },

    {
        text: "Touch Paint",
        imageSource: 'touchPaintLink2.png',
        routerLink: {name: 'touchPaint'},
        detailsText: "Paint on an HTML canvas using touch presses",
        tags: ['web project', 'touch'],
    },
    {
        text: "caveman",
        imageSource: 'cavemanLink.png',
        routerLink: {name: 'caveman'},
        detailsText: "A tile scrolling platform game",
        tags: ['game'],
    },
    // {
    //     text: "Tomb Of The Pharaoh",
    //     imageSource: 'pharaoh.png',
    //     routerLink: {name: 'raycasterWalls'},
    //     detailsText: "A raycasted walls puzzle game",
    //     tags: ['game'],
    // },
    {
        text: "3D Objects",
        imageSource: 'objectRenderLink.png',
        routerLink: {name: 'objectRender'},
        detailsText: "Render and move around 3D wireframe objects",
        tags: ['animation'],
    },
    {
        text: "Game Controllers",
        imageSource: 'gameControllerLink.png',
        routerLink: {name: 'gameController'},
        detailsText: "Link a up to 4 controllers and display their inputs",
        tags: ['web project'],
    },
    {
        text: "fish bowl",
        imageSource: 'fishBowlLink2.png',
        routerLink: {name: 'FishBowl'},
        detailsText: "Watch the fish and other sealife swim by in a vector graphic animation",
        tags: ['animation'],
    },
    {
        text: "Canvas Buttons",
        imageSource: 'buttonsLink.png',
        routerLink: {name: 'JsButtons'},
        detailsText: "A selection of Javascript buttons types on an HTML canvas",
        tags: ['web project'],
    },
    {
        text: "Gallery",
        imageSource: 'carouselLink.png',
        routerLink: {name: 'carousel'},
        detailsText: "A gallery of images with a carousel to scroll through items",
        tags: ['web project', 'touch'],
    },
    {
        text: "Canvas Text Boxes",
        imageSource: 'textBoxLink.png',
        routerLink: {name: 'textBoxes'},
        detailsText: "Type text into a box and edit how it's displayed on an HTML canvas",
        tags: ['web project'],
    },
    {
        text: "Drum Kit",
        imageSource: 'drumKitLink.png',
        routerLink: {name: 'drumKit'},
        detailsText: "Use a mouse or touch to play sounds on a drum kit",
        tags: ['web project', 'touch'],
    }
]