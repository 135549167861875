<template>
    <div @click="selectTag()"  class="tag-option"  :class="{open: isSelected}">
        <img class="checkBox" :src="require(`@/assets/images/${imageName}.svg`)" />
        <div class="tag-name">{{ tagName }}</div>
    </div>
</template>

<script>
export default {
    name: 'FilterOption',
    props: {
        tagName: {
            type: String,
            default: '',
        },
        filterFunction: {
            type: Function,
            default: () => {},
        },
        filterSelected: {
            type: String,
            default: '',
        },
    },
    computed: {
        isSelected() {
            return this.tagName === this.filterSelected
        },
        imageName() {
            return  this.isSelected ? 'checkTicked' : 'checkUnticked' ;
        }
    },
    methods: {
        selectTag() {
            this.filterFunction(this.tagName);
        }
    }
}
</script>

<style lang="scss" scoped>
    .tag-option {
        text-transform: capitalize;
        display: flex;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        min-width: 110px;
        color: rgb(57, 50, 50);
        border: 3px solid rgb(176, 156, 156);
        border-radius: 25px;
        padding: 2px 15px;
        cursor: pointer;
        margin: 2px 2px;

        &.open {
          color: black;
          border: 3px solid black;
        }
    }

    .checkBox {
        width: 20px;
        padding: 0px 2px;
    }
</style>

