<template>
    <div class="main-project-container">
        <div class="main-project-link">
            <ProjectLink 
                :text="projectData.text"
                :imageSource="imageSource"
                :routerLink="projectData.routerLink"
            />
            <div 
                class="details-overlay-container" 
                @click="$router.push(projectData.routerLink)"
            >
                <div class="details-overlay">
                    <p class="title"> {{ projectData.text }} </p>
                    <p> {{ projectData.detailsText }} </p>
                    <p class="details-tags">
                        {{ categorys }}
                    </p>
                </div>
            </div>
        </div>

        <div class="details-drop-container" >
            <div class="details-banner" @click="toggleDrop">
                <img class="drop-arrow" :class="{open: dropOpen}" src="@/assets/images/selectArrow.svg" />
                <div class="details-title">
                    {{dropOpen ? 'Hide' : 'Details'}}
                </div>
            </div>
            <div class="details-text" v-if="dropOpen">
                {{ projectData.detailsText }}
            </div>
        </div>
    </div>
</template>

<script>
import ProjectLink from "@/components/ProjectLink";

export default {
    name: 'TestMainLink',
    components: {
        ProjectLink, 
    },
    props: {
        imageSource: {
            required: true,
            type: String,
        },
        projectData: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            dropOpen: false,
        }
    },
    computed: {
        categorys() {
            let tagsString = 'Categorys: '
            this.projectData?.tags.forEach(tag => {
                tagsString += `${tag}, `;
            });
            return tagsString;
        },
    },
    methods:  {
        toggleDrop() {
            this.dropOpen = !this.dropOpen;
        }
    },
}
</script>

<style lang="scss" scoped>
    $tablet-width: 950px;

    .main-project-container {
        display: flex;
        flex-direction: column;
        margin: 15px;
    }

    .main-project-link {
        position: relative;
        width: 400px;

        @media only screen and (max-width: $tablet-width) {
            width: 250px;
        }
    }

    .details-overlay-container {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border: 6px solid var(--color3);
        padding: 10px;
        opacity: 0;
        transition: opacity .6s;
        background: #000;
        cursor: pointer;

        @media only screen and (max-width: $tablet-width) {
            display: none;
        }
    }

    .details-overlay {
        color: #f0ebeb;
        width: 100%;
        height: 100%;
        font-size: 18px;
    }

    .details-overlay-container:not(.hide-notes):hover{
        opacity: 0.92;
    }

    .title {
        text-transform: capitalize;
        font-size: 24px;
    }

    .details-tags {
       position: absolute;
       bottom: 0;
       text-transform: capitalize;
    }

    .details-drop-container {
        width: 100%;
        max-width: 250px;
        
        @media only screen and (min-width: $tablet-width) {
            display: none;
        }

    }

    .details-banner {
        height: 35px;
        background: var(--color3);
        display: flex;
        padding: 0px 5px;
        justify-content: center;
        cursor: pointer;
    }

    .details-title {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        width: 40%;
        color: #000;
        text-transform: uppercase;
        padding: 0px 10px;
    
    }

    .details-text {
        background: #fff;
        display: flex;
        padding: 10px 5px;
        text-align: left;
        margin-bottom: 5px;
    }

    .drop-arrow {
        transition: transform 0.3s;
        width: 25px;

        &.open {
          transform: scaleY(-1)
        }
    }
 
</style>